import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SimplePeer from 'simple-peer'; // Left for later use if needed
import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';
import AgoraRTC from "agora-rtc-sdk-ng"; // Agora SDK import
import './Home.css';
import Footer from './Footer';
import TC from './TC';
import Header from './Header';
import './Game.css';
import './MobileHome.css';
import './MobileGame.css';
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify for alerts
import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS

// Agora credentials
const APP_ID = '98b6caaeb68f4a7b92704c67e7b63350';
const TEMP_TOKEN = '007eJxTYLCdcMBfYfLz9UduJ3K8rgmL9X96hjtsmu1779pDF/dGb3yuwGBpkWSWnJiYmmRmkWaSaJ5kaWRuYJJsZp5qnmRmbGxqoHmCJ70hkJFBbWIaKyMDBIL4TAzJRQwMAIYfH4c=';
const CHANNEL_NAME = 'cr';

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" }); // Initialize Agora client

// Update the WebSocket URL to your Render backend
const socket = io('https://chessica.onrender.com'); // Replace with your Render server address

const generateGameId = () => Math.random().toString(36).substr(2, 9);

const App = () => {
  const [onlineCount, setOnlineCount] = useState(0);
  const [inCall, setInCall] = useState(false);
  const [searching, setSearching] = useState(false);
  const [chess, setChess] = useState(new Chess());
  const [gameStarted, setGameStarted] = useState(false);
  const [color, setColor] = useState('white');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [selectedSquare, setSelectedSquare] = useState(null); // For click-to-move
  const myVideo = useRef();
  const partnerVideo = useRef();
  const peerRef = useRef(); // Keeping this in case you switch back to WebRTC later
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  // Agora variables
  const localTracks = useRef([]);
  const remoteUsers = useRef({});

  // Set up portrait/landscape detection
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = (event) => {
      setIsPortrait(event.matches);
    };

    mediaQuery.addEventListener('change', handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    axios.get('https://chessica.onrender.com/count')
      .then(response => setOnlineCount(response.data.count))
      .catch(error => console.error(error));

    socket.on('updateCount', count => setOnlineCount(count));

    return () => {
      socket.off('updateCount');
    };
  }, []);

  const handlePlay = () => {
    if (isTermsChecked) {
      setSearching(true);
      socket.emit('play');
    } else {
      toast.error("Please accept the Terms and Conditions."); // Replacing alert with toast
    }
  };

  // Agora functions
  const joinAgora = async (initiator) => {
    try {
      await client.join(APP_ID, CHANNEL_NAME, TEMP_TOKEN, null);
      
      // Get the local video and audio tracks
      const [audioTrack, videoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
      localTracks.current = [audioTrack, videoTrack];

      // Play the local video track
      videoTrack.play(myVideo.current);

      // Publish local tracks to the channel
      await client.publish(localTracks.current);

      // Subscribe to remote users
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === 'video') {
          const remoteTrack = user.videoTrack;
          remoteTrack.play(partnerVideo.current);
        }
      });

    } catch (error) {
      console.error("Agora join failed: ", error);
      toast.error("Failed to join the Agora video call.");
    }
  };

  useEffect(() => {
    socket.on('paired', async ({ initiator }) => {
      setInCall(true);
      setSearching(false);
      setGameStarted(true);
      setColor(initiator ? 'white' : 'black');

      const gameId = generateGameId();
      navigate(`/game`);

      // Join Agora when paired
      joinAgora(initiator);
    });

    socket.on('move', (move) => {
      const game = new Chess(chess.fen());
      game.move(move);
      setChess(game);

      setTimeout(() => {
        if (game.isCheckmate()) {
          toast.success('Checkmate! The game is over.');
          socket.emit('gameOver', { type: 'checkmate' });
        } else if (game.isStalemate()) {
          toast.info('Stalemate! The game is a draw.');
          socket.emit('gameOver', { type: 'stalemate' });
        }
      }, 100);
    });

    socket.on('gameOver', ({ type }) => {
      if (type === 'checkmate') {
        toast.success('Checkmate! The game is over.');
      } else if (type === 'stalemate') {
        toast.info('Stalemate! The game is a draw.');
      }
    });

    return () => {
      socket.off('paired');
      socket.off('move');
      socket.off('gameOver');
    };
  }, [chess, navigate]);

  const handleMove = (sourceSquare, targetSquare) => {
    const game = new Chess(chess.fen());
    const move = { from: sourceSquare, to: targetSquare, promotion: 'q' };
  
    const playerInCheckBeforeMove = game.inCheck();
  
    if ((game.turn() === 'w' && color === 'white') || (game.turn() === 'b' && color === 'black')) {
      const validMoves = game.moves({ verbose: true });
      const isValidMove = validMoves.some(validMove => 
        validMove.from === sourceSquare && validMove.to === targetSquare
      );
  
      if (isValidMove) {
        game.move(move);
        setChess(game);
        socket.emit('move', move);  // Emit the move to the server
        setSelectedSquare(null);    // Clear selected square after move
  
        setTimeout(() => {
          if (game.isCheckmate()) {
            toast.success('Checkmate! The game is over.'); 
            socket.emit('gameOver', { type: 'checkmate' });
          } else if (game.isStalemate()) {
            toast.info('Stalemate! The game is a draw.'); 
            socket.emit('gameOver', { type: 'stalemate' });
          }
        }, 100);  
      } else {
        if (playerInCheckBeforeMove) {
          toast.error("Invalid move! You're in check and must move out of check."); 
        } else {
          console.error('Invalid move:', move);
        }
      }
    } else {
      console.warn('Not your turn:', move);
    }
  };
  
  const handleSquareClick = (square) => {
    const game = new Chess(chess.fen());
    const piece = game.get(square);
  
    if (selectedSquare) {
      const validMoves = game.moves({ square: selectedSquare, verbose: true });
      const isValidMove = validMoves.some(validMove => validMove.to === square);
  
      if (isValidMove) {
        handleMove(selectedSquare, square); 
      } else {
        if (game.inCheck()) {
          toast.error("Invalid move! You're in check and must move out of check."); 
        }
      }
      setSelectedSquare(null); 
    } else if (piece && ((piece.color === 'w' && color === 'white') || (piece.color === 'b' && color === 'black'))) {
      setSelectedSquare(square);
    } else {
      setSelectedSquare(null);
    }
  };

  const customSquareStyles = {
    ...(selectedSquare && { 
      [selectedSquare]: { 
        boxShadow: 'inset 0 0 0 3px rgba(255, 255, 0, 0.6)' 
      }
    })
  };

  return (
    <div className="app">
      <Header />
      <div className="content">
        <Routes>
          <Route
            path="/"
            element={
              !inCall && (
                <div className='main-content'>
                  <div className="title">Video Call and Play Chess with Strangers around the World</div>
                  
                  <p className="subtitle">Total People Online: {onlineCount}</p>
                  <div className="terms-and-conditions">
                    <input 
                      type="checkbox" 
                      id="terms" 
                      checked={isTermsChecked}
                      onChange={() => setIsTermsChecked(!isTermsChecked)}
                    />
                    <label htmlFor="terms" style={{ marginLeft: '8px' }}>
                      I agree to the{' '}
                      <span 
                        onClick={() => setShowTerms(true)} 
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        Terms and Conditions
                      </span>
                    </label>
                  </div>
                  <br />
                  <button className="play-button" onClick={handlePlay} disabled={searching || !isTermsChecked}>
                    {searching ? 'Searching for an opponent...' : 'Play'}
                  </button>
                  <TC showTerms={showTerms} setShowTerms={setShowTerms} />
                </div>
              )
            }
          />

          <Route
            path="/game"
            element={
              inCall && (
                <div className="game-container">
                  <div className="chess-and-video">
                    {isPortrait && <div className="videos">
                      <video ref={myVideo} autoPlay muted className="video" playsInline controls={false} />
                      <video ref={partnerVideo} autoPlay className="video" playsInline controls={false} />
                    </div>}
                    <div className="chessboard-container">
                      <Chessboard
                        position={chess.fen()}
                        onPieceDrop={(sourceSquare, targetSquare) => handleMove(sourceSquare, targetSquare)}
                        onSquareClick={handleSquareClick}
                        customSquareStyles={customSquareStyles}
                        customLightSquareStyle={{ backgroundColor: '#f5f5f5' }}
                        customDarkSquareStyle={{ backgroundColor: '#717374' }}
                        boardOrientation={color}
                        boardWidth={isPortrait ? Math.min(window.innerWidth * 0.8, 600) : Math.min(window.innerWidth * 0.4, 600)}
                      />
                    </div>
                    {!isPortrait && <div className="videos">
                      <video ref={myVideo} autoPlay muted className="video" />
                      <video ref={partnerVideo} autoPlay className="video" />
                    </div>}
                  </div>
                </div>
              )
            }
          />
        </Routes>
      </div>

      {location.pathname === '/' && <Footer />}
      <ToastContainer /> {/* Add ToastContainer to enable toasts */}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
